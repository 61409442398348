import { useEffect, useState } from "react";
import { User } from "../AuthenticatedHome";
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid";
import Status from "../../Components/Status";
import { Menu, MenuItem } from "@mui/material";
import { FaArrowLeft, FaFileCirclePlus } from "react-icons/fa6";
import ProjectContainer, { SOW } from "../../Components/ProjectContainer";
import DragContainer from "../../Components/DragContainer";
import { Button } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase

export interface ProjectTicket {
    id: string;
    dateRaised?: string;
    title: string;
    description: string;
    deadline?: Date | undefined;
    prefix: string;
    priority?: string;
    user?: User;
    department?: string;
    timeSpent?: string;
}

export interface Project {
    id: string;
    projectName: string;
    user?: string;
    tickets: {
        Queued: ProjectTicket[];
        "In Progress": ProjectTicket[];
        Testing: ProjectTicket[];
        Completed: ProjectTicket[];
    };
    status: string;
    scopeFileUrl?: string; // Optional URL for scope file
}

interface Props {
    allData: Project[];
    saveProject: (project: Project) => void;
    onTicketCreate: (ticket: ProjectTicket, project: Project) => void;
    saveChange: (project: Project) => void;
    saveSow: (sow: SOW) => void;
}

const Developer = ({ allData, saveProject, onTicketCreate, saveChange, saveSow }: Props) => {
    const [viewing, setViewing] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedProject, setSelectedProject] = useState<Project | undefined>();
    const [create, setCreate] = useState<boolean>(false);
    const [scopeFileUploaded, setScopeFileUploaded] = useState<boolean>(false);

    // Context menu state
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const storage = getStorage(); // Initialize Firebase storage

    useEffect(() => {
        if (selectedProject) {
            setScopeFileUploaded(!!selectedProject.scopeFileUrl);
        }
    }, [selectedProject]);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : null
        );
    };

    const handleScopeUpload = async (file: File) => {
        if (!file.name.endsWith(".docx")) {
            alert("Please upload a valid .docx file.");
            return;
        }

        if (selectedProject) {
            const fileRef = ref(storage, `scopes/${selectedProject.id}.docx`);
            await uploadBytes(fileRef, file);
            const url = await getDownloadURL(fileRef);

            // Update project with scope file URL
            const updatedProject = { ...selectedProject, scopeFileUrl: url };
            saveProject(updatedProject);
            setSelectedProject(updatedProject);
            setScopeFileUploaded(true);
        }
    };

    const handleScopeDownload = () => {
        if (selectedProject?.scopeFileUrl) {
            window.open(selectedProject.scopeFileUrl, "_blank");
        }
    };

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) handleScopeUpload(file);
    };

    const rows: GridRowsProp = allData.map((item) => ({
        id: item.id,
        projectName: item.projectName,
        user: item.user || "",
        status: <Status className="w-75" text={item.status} />,
        queued: `${item?.tickets.Queued.length} Ticket${item?.tickets.Queued.length === 1 ? "" : "s"}`,
        inProgress: `${item?.tickets["In Progress"].length} Ticket${item?.tickets["In Progress"].length === 1 ? "" : "s"}`,
        testing: `${item?.tickets.Testing.length} Ticket${item?.tickets.Testing.length === 1 ? "" : "s"}`,
        completed: `${item?.tickets.Completed.length} Ticket${item?.tickets.Completed.length === 1 ? "" : "s"}`,
        tickets: item.tickets,
    }));

    const columns: GridColDef[] = [
        { field: "id", headerName: "PROJECT ID", maxWidth: 200, flex: 1 },
        { field: "projectName", headerName: "PROJECT NAME", minWidth: 400, flex: 1 },
        { field: "user", headerName: "MAIN LEAD", maxWidth: 200, flex: 1 },
        { field: "queued", headerName: "QUEUED", maxWidth: 200, flex: 1 },
        { field: "inProgress", headerName: "IN PROGRESS", maxWidth: 200, flex: 1 },
        { field: "testing", headerName: "TESTING", maxWidth: 200, flex: 1 },
        { field: "completed", headerName: "COMPLETED", maxWidth: 200, flex: 1 },
    ];

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    return (
        <ProjectContainer
            saveSow={saveSow}
            projects={allData}
            create={create}
            closeProject={() => setCreate(false)}
            saveProject={saveProject}
        >
            <>
                {viewing ? (
                    selectedProject && (
                        <div className="p-2">
                            <div className="d-flex flex-row justify-content-between mb-2">
                                <a onClick={() => setViewing(false)} className="d-flex flex-row align-items-center back-arrow m-0">
                                    <FaArrowLeft color="#130922" /> Return to table
                                </a>
                                {scopeFileUploaded ? (
                                    <Button onClick={handleScopeDownload} className="buttonES">
                                        Download Scope
                                    </Button>
                                ) : (
                                    <Button as="label" className="buttonES">
                                        Upload Scope
                                        <input type="file" accept=".docx" hidden onChange={handleFileInput} />
                                    </Button>
                                )}
                            </div>
                            <DragContainer saveChange={saveChange} onTicketCreate={onTicketCreate} project={selectedProject} />
                        </div>
                    )
                ) : (
                    <div className="assetReg" onContextMenu={handleContextMenu}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                            rowSelectionModel={selectionModel}
                            onRowClick={(item) => {
                                const project = allData.find((data) => data.id === item.row.id);
                                setSelectedProject(project);
                                setViewing(true);
                            }}
                        />
                        <Menu
                            open={contextMenu !== null}
                            onClose={handleCloseContextMenu}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenu !== null
                                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                    : undefined
                            }
                        >
                            <MenuItem onClick={() => setCreate(true)}>
                                <span className="d-flex flex-row align-items-center">
                                    <FaFileCirclePlus color="white" className="mr1" />
                                    Create new project
                                </span>
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </>
        </ProjectContainer>
    );
};

export default Developer;
