import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ProjectTicket } from "../Pages/SubPages/Developer";
import moment from "moment";

interface Props {
  item: ProjectTicket;
  index: number;
  prefix: string;
  onClick: () => void;
}

const formatDeadline = (deadline: any): string => {
  if (!deadline) return "No deadline";
  // If it's a Firestore Timestamp (check for `toDate` function)
  if (typeof deadline.toDate === "function") {
    return moment(deadline.toDate()).format("DD/MM/YYYY");
  }
  // If it's a standard Date object or ISO string
  return moment(deadline).isValid() ? moment(deadline).format("DD/MM/YYYY") : "Invalid date";
};

const ListItem = ({ item, index, prefix, onClick }: Props) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          onClick={onClick}
          className={`projectCard 
            ${prefix === "In Progress" && "projectCard--orange"} 
            ${prefix === "Testing" && "projectCard--orange"} 
            ${prefix === "Completed" && "projectCard--green"}
          `}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="d-flex flex-row justify-content-between align-items-center mb-2">
            <div className="d-flex flex-row justify-content-between">
              <p
                className={`m-0 mr1 projectCard__id 
                 ${prefix === "In Progress" && "projectCard__id--orange"} 
                 ${prefix === "Testing" && "projectCard__id--orange"} 
                 ${prefix === "Completed" && "projectCard__id--green"}
              `}
              >
                {item.id}
              </p>
              {item.priority && (
                <p
                  className={`m-0 projectCard__id
                   ${item.priority === "Medium Priority" && "projectCard__id--orange"} 
                   ${item.priority === "High Priority" && "projectCard__id--red"}
                `}
                >
                  {item.priority}
                </p>
              )}
            </div>
            <p className="projectCard__name">
              {item.user?.name
                .split(" ")
                .map((namePart) => namePart[0])
                .join("")}
            </p>
          </div>
          {item.dateRaised && (
            <div className="d-flex flex-row mb-2">
              <div className="d-flex flex-column mr-2">
                <p className="m-0 projectCard__date">Created:</p>
                <p className="m-0 projectCard__date">
                  {moment(item.dateRaised).format("DD/MM/YYYY")}
                </p>
              </div>
              {item.deadline && (
                <div className="d-flex flex-column">
                  <p className="m-0 projectCard__date">Deadline:</p>
                  <p className="m-0 projectCard__date">
                    {formatDeadline(item.deadline)}
                  </p>
                </div>
              )}
            </div>
          )}
          <p className="m-0 projectCard__title">{item.title}</p>
          <p className="m-0 projectCard__desc">{item.description}</p>
        </div>
      )}
    </Draggable>
  );
};

export default ListItem;
